


























  import {
    Component,
    Vue
  } from "vue-property-decorator";
  import search from '@/components/global/search.vue'
  import {
    api
  } from "@/api"
  import {
    onScroll
  } from "@/utils/func";
import { ParamModule } from "@/store/modules/param";

  @Component({
    name: 'material-list',
    components: {
      search
    }
  })
  export default class MaterialList extends Vue {
    private pageNo = 1
    private pageSize = 10
    private hasNextPage = true
    private isRequest = false
    private materialList: any = []
    private MaterialType: any = {
      img: 1,
      video: 2,
      text: 3
    }

    private mounted() {
      this.getList()
    }

    /**
     * @func 搜索回调
     */
    private handleSearch(query ? : string) {
      this.pageNo = 1
      this.hasNextPage = true
      this.materialList = []
      this.getList(query || '')
    }

    /**
     * @func 搜索结果
     */
    private async getList(query ? : string) {
      if (this.isRequest) return
      if (!this.hasNextPage) return;
      this.isRequest = true
      let param = {
        page_no: this.pageNo,
        page_size: this.pageSize,
        material_type: 0,
        name: ''
      }
      if (query) {
        param.name = query
      }
      try {
        const res: any = await api.getMaterialList(param);
        !res.list || res.list.length < this.pageSize ?
          this.hasNextPage = false : this.pageNo++
        if (res.list) {
          res.list.map((item: any) => {
            if (item.url.indexOf(']') !== -1) {
              item.url = JSON.parse(item.url);
            }
            this.materialList.push(item);
          })
        }
        this.isRequest = false
      } catch (err) {
        this.isRequest = false
        console.log(err)
      }
    }

    /**
     * @func 监听滚动
     */
    private listenScroll(e: any) {
      onScroll({
        e: e,
        scrollUp: () => {
          if (this.materialList.length > 0) {
            this.getList();
          }
        }
      })
    }

    /**
     * @func 点击选择素材
     */
    private doSelect(material: any) {
      ParamModule.changeSelectMaterial(material)
      this.$router.go(-1)
    }
  }
